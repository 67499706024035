<template>
  <div style="width: 100%;" @click="handleClear">
    <page-header
      class="absolute"
      style="padding: 20px 30px; width: calc(100% - 335px)"
      :title="__('Notifications')"
    />

    <el-button
      class="createBtn"
      @click.stop="createContentItem"
      :disabled="!can('manage.notifications.write')"
      >{{ __("New Notification") }}</el-button
    >

    <div style="padding: 0 36px; margin-top: 136px;" v-loading="isLoading">
      <el-form class="form-container">
        <PaginationToolbar
          :sort-by-options="sortByOptions"
          :content-api="contentAPI"
          @success="$emit('replaceContent', $event)"
        >
        </PaginationToolbar>
      </el-form>
      <el-scrollbar :native="true">
        <div class="contentListContainer">
          <el-table
            ref="notificationTable"
            class="list-table"
            :data="notifications"
            highlight-current-row
            @row-click="handleSingleClick"
            @row-dblclick="handleDoubleClick"
          >
            <el-table-column
              :label="__('Subject')"
              prop="notification_subject"
            />
            <el-table-column
              :label="__('Notification period')"
              v-if="isSystemUser"
            >
              <template slot-scope="scope">
                <el-row
                  type="flex"
                  justify="flex-start"
                  style="align-items: center"
                  v-if="scope.row.start_date && scope.row.end_date"
                >
                  {{ formattedDateTime(scope.row.start_date) }}
                  <span style="padding-left: 5px; padding-right: 5px">{{
                    __("to")
                  }}</span>
                  {{ formattedDateTime(scope.row.end_date) }}
                </el-row>
                <span v-else>{{ __("N/A") }}</span>
              </template>
            </el-table-column>
            <el-table-column
              v-else
              :label="__('Received at')"
              prop="created_at"
            >
              <template slot-scope="scope">
                {{ formattedDateTime(scope.row.created_at) }}
              </template>
            </el-table-column>
            <el-table-column :label="__('Status')" prop="status" />
          </el-table>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import PaginationToolbar from "@/components/PaginationToolbar";
import PageHeader from "@/components/PageHeader";
import BaseContent from "@/views/build/content/mixins/BaseContent";

export default {
  name: "NotificationsManageList",
  mixins: [BaseContent],
  components: {
    PaginationToolbar,
    PageHeader
  },
  props: {
    msg: {
      required: false,
      type: String,
      default: ""
    }
  },
  data() {
    return {
      sortByColumns: ["notification_subject", "notification_message_id"],
      fetchedNotifications: false,
      search: "",
      filters: ["notification_subject"],
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10
      }
    };
  },

  computed: {
    ...mapState("notifications", {
      notifications: state => state.notifications,
      isLoading: state => state.isLoading
    }),

    ...mapGetters("app", {
      formattedDateTime: "formattedDateTime"
    }),

    notificationsToShow() {
      return this.notifications.filter(
        notification =>
          !this.search ||
          this.filterNotificationOnSearchQuery(notification, this.search)
      );
    },

    isSystemUser() {
      return this.$auth.user().roles.includes("system");
    }
  },

  mounted() {
    if (this.msg) {
      this.$message({
        type: "success",
        message: this.msg
      });
    }
  },
  methods: {
    ...mapActions("notifications", {
      contentAPI: "getSpNotifications",
      deleteContentMethod: "deleteNotification",
      undoDeleteContent: "undoDeleteNotification"
    }),

    handleSingleClick(row, column, event) {
      event.stopPropagation();
      this.handleSelect(null, row);
    },

    handleDoubleClick(row, column, event) {
      event.stopPropagation();
      this.handleEdit(null, row);
    },

    handleClear() {
      this.$refs.notificationTable &&
        this.$refs.notificationTable.setCurrentRow();
      this.handleClearSelection();
    },

    handleDeleteItem(index, notification) {
      this.handleDelete(notification);
    }
  },
  watch: {
    selectedItemIndex: {
      immediate: true,
      handler: function(val) {
        if (val !== -1 && val !== null && val !== undefined) {
          this.$refs.notificationTable &&
            this.$refs.notificationTable.setCurrentRow(this.notifications[val]);
        } else if (val === -1) {
          this.$refs.notificationTable &&
            this.$refs.notificationTable.setCurrentRow();
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
$card-image-filter: invert(65%) sepia(43%) saturate(5014%) hue-rotate(170deg)
  brightness(104%) contrast(89%);
@import "~@/styles/content-buttons.scss";
@import "~@/styles/content-list.scss";
@import "~@/styles/pagination-toolbar.scss";
.rounded-menu-icon {
  height: 44px;
  width: 44px;
  border-radius: 50%;
  object-fit: cover;
  background: white;
}
</style>
