<script>
import List from "./pages/list";
import CreateOrEdit from "./pages/createOrEdit";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import { mapActions } from "vuex";

export default {
  name: "NotificationsManageIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },

  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  methods: {
    ...mapActions("users", {
      getUsers: "getUsers"
    }),

    initContent() {
      return {
        notification_subject: "",
        content: "",
        target_user_group: "all_users",
        start_date: "",
        end_date: "",
        recipients: []
      };
    }
  },

  async created() {
    await this.getUsers({ fetch_all: 1 });
  },

  mounted() {
    this.contentType = "Notification";
    this.primaryKey = "notification_message_id";
    this.scope = "sp";
  },

  watch: {
    selectedServiceProviderAccountCombination: {
      immediate: true,
      async handler() {
        await this.getUsers({ fetch_all: 1 });
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
